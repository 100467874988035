<template>
  <section class="constrain text-center mt-2-header-height">
    <Image :src="payload.icon" :inline="false" class="w-12 mx-auto" />
  </section>
</template>

<script>
export default {
  props: { payload: { type: Object, default: Object } },
};
</script>

<style lang="scss" scoped>
.mt-2-header-height {
  margin-top: calc(2 * var(--header-height));
}
</style>
